import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

interface AuthState {
  isLoggedIn: boolean;
  role: any;
  isInstituteLogin: boolean;
  isTeacherLogin: boolean;
  isStudentLogin: boolean;
  isSchoolLogin: boolean;
}

const initialState: AuthState = {
  isLoggedIn:
    Cookies.get("token") && Cookies.get("role") === "admin" ? true : false,
  role: Cookies.get("role") ? Cookies.get("role") : "null",
  isInstituteLogin:
    Cookies.get("token") && Cookies.get("role") === "superAdmin" ? true : false,
  isTeacherLogin:
    Cookies.get("token") && Cookies.get("role") === "teacher" ? true : false,
  isStudentLogin:
    Cookies.get("token") && Cookies.get("role") === "student" ? true : false,
    isSchoolLogin:
    Cookies.get("token") && Cookies.get("role") === "admin" ? true : false,
};

const loginSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      if (Cookies.get("role") === "admin") {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    logout: (state) => {
      state.isInstituteLogin = false;
      state.isTeacherLogin = false;
      state.isStudentLogin = false;
      state.isLoggedIn = false;
    },
    instituteLogin: (state) => {
      if (Cookies.get("role") === "superAdmin") {
        state.isInstituteLogin = true;
      } else {
        state.isInstituteLogin = false;
      }
    },
    teacherLogin: (state) => {
      if (Cookies.get("role") === "teacher") {
        state.isTeacherLogin = true;
      } else {
        state.isTeacherLogin = false;
      }
    },
    studentLogin: (state) => {
      if (Cookies.get("role") === "student") {
        state.isStudentLogin = true;
      } else {
        state.isStudentLogin = false;
      }
    },
    schoolLogin: (state) => {
        if (Cookies.get("role") === "admin") {
          state.isSchoolLogin = true;
        } else {
          state.isSchoolLogin = false;
        }
      },
    role: (state) => {
      state.role = Cookies.get("role") ? Cookies.get("role") : "null";
    },
  },
});

export const { login, logout, instituteLogin, teacherLogin, studentLogin,schoolLogin  } =
  loginSlice.actions;
export default loginSlice.reducer;
