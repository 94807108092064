import { adminTypes } from "../types/AdminType";
import instance from "./instace"


export const createAdmin = async(data:adminTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/create',{
            name:data.name,
            email:data.email,
            password:data.password,
            confirmPassword:data.confirmPassword
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const loginAdmin = async(email:string,password:string)=>{
    try{
        const get:any = await instance.post('/api/admin/login',{
           email,password
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const logoutService = async ()=>{
    try{
        const get:any = await instance.post('/api/logout')
        return get.data;
    }catch(e){
        return false;
    }
}