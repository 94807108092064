import Box from "@mui/material/Box";
import {
  DataGrid,
  GridRowSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState} from "react";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { ThemeProvider, Tooltip, createTheme } from '@mui/material';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaClock, FaEdit, FaEye, FaPlay, FaRupeeSign, FaSpinner, FaStopwatch, FaTrash, FaUsers, FaVideo, FaVideoSlash, FaVimeo, FaYoutube } from "react-icons/fa";
import Swal from 'sweetalert2'
import TableButton from "../button/TableButton";
import moment from "moment";
import CustomButton from "../button/CustomButton";
import { FaBookAtlas } from "react-icons/fa6";
import { deleteAddressBook, getAddressBook } from "../../service/addressbookService";
import { StringShortner } from "../../helpers/commonHelpers";
import { deleteImageCategory, getImageCategory, updateStatusImageCategory } from "../../service/imageCategoet.service";
import SwitchButton from "../button/SwitchButton";
import Models from "../models/Models";
import AdminImageCategoryCE from "../../pages/admin/images/imageCategory/AdminImageCategoryCE";
import { deleteImage, getImage, updateStatusImage } from "../../service/images.service";



export default function  PaginationDataGrid(props: {
  name: String;
  id?:any;
  refresh?: any;
  height?: any;
  dataId?:any;
  dataObject?:any;
  checkbox?: boolean;
  onSubmit?:any
}) {
    const themeSlice = useSelector((state:any) => state.themeSlice)
    const theme:any = createTheme({
        palette: {
            mode: themeSlice? themeSlice : 'light',
          },
      });
  const [open, setOpen] = useState<any>(false)
  const [open1, setOpen1] = useState<any>(false)
  const navigate = useNavigate();
  const [columnWidths, setColumnWidths] = useState({});

  const handleColumnWidthChange = (newColumnWidths:any) => {
    setColumnWidths(newColumnWidths);
  };
  const [loading, setloading] = useState<boolean>(false);
  let columns: any = [];
  let [tableRow, settableRow] = useState<any>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [updateModelData, setUpdateModelData] = useState<any>();
  const [rowLoad, setRowLoad] = useState(false)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [modelData, setModelDate] = useState<any>([]);
  const [rowid, setRowid] = useState<any>(1)
  const [rowCountState, setRowCountState] = useState(totalRow || 0);
  const courseUpdateData = useSelector((state:any) => state.courseUpdateSlice)
  const contentUpdate = useSelector((state:any) => state.contentUpdateSlice)
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<any>({
    items: [],
    quickFilterValues: [],
  });

  const addressBookData = async()=>{
    setloading(true)
    let get;
    if (filterModel === null || filterModel === undefined || filterModel === "") {
       get = await getAddressBook(page);
    } else {
      get = await getAddressBook(page,filterModel.quickFilterValues.join(' '));
    }
    setTotalPages(get.message.totalPage * pageSize);
    setTotalRow(get.message.totalRow);
    let dt: any = [];
    if (get?.status == "success") {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
      dt.push({
        id: item.id,
        uuid:index+rowid,
        name: item.name,
        phone:item.phone,
        email: item.email,
        dob: moment(item.dob).format('DD-MM-YYYY'),
        website: item.website,
        group: item.group,
      });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  }
  if(props.name === "addressBookData"){
    columns = [
      { field: "uuid", headerName: "ID", flex: 0.01,minWidth: 100,sortable:false},
      { field: "name", headerName: "Name", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
        return (
         <Tooltip title={params.row.name}>
          <p >{ StringShortner(params.row.name,10)}</p>
         </Tooltip>
        )
      }},
      { field: "phone", headerName: "Phone", flex: 0.14 ,minWidth: 150,sortable:false},
      { field: "email", headerName: "Email", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
        return (
         <Tooltip title={params.row.email}>
          <p >{ StringShortner(params.row.email,10)}</p>
         </Tooltip>
        )
      }},
      { field: "dob", headerName: "DOB", flex: 0.14 ,minWidth: 150,sortable:false},
      { field: "group", headerName: "Group", flex: 0.14 ,minWidth: 150,sortable:false},
      { field: "", headerName: "Action", flex: 0.14 ,minWidth: 150,sortable:false, renderCell: (params: any) => {
          const handleDelete =  async()=>{
            let classname = `delete ${params.row.name}`;
          Swal.fire({
            title: 'Are you sure want to delete ?',
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async(value) => {
              const del:any = await deleteAddressBook(params.row.id)
                  if(del?.status === 'success'){
                    const newData = tableRow.filter((item:any)=> item.id !== params.row.id);
                    settableRow(newData);
                    setTotalPages(totalPages-1);
                    setTotalRow(totalRow-1);
                   return toast.success(del.message);
                  }

            }
          })

          }
          const handleWebsite = async()=>{
            let uri = params.row.website
            window.open(uri, '_blank');
          }
          return (
            <div className="flex gap-4 flex-row">
              <TableButton title="website" icon={true}   onClick={handleWebsite} toottip="website" />
              <TableButton title="edit" icon={true}   onClick={(()=>navigate(`/admin/addressbook/${params.row.id}/edit`))} toottip="edit" />
              <TableButton title="delete" icon={true}   onClick={handleDelete} toottip="delete" />
            </div>
          );
        }},
    ]
  }
  const adminImageCategory = async()=>{
    setloading(true)
    let get;
    if (filterModel === null || filterModel === undefined || filterModel === "") {
       get = await getImageCategory(page);
    } else {
      get = await getImageCategory(page,filterModel.quickFilterValues.join(' '));
    }
    setTotalPages(get.message.totalPage * pageSize);
    setTotalRow(get.message.totalRow);
    let dt: any = [];
    if (get?.status == "success") {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
      dt.push({
        id: item.id,
        uuid:index+rowid,
        name: item.name,
       status: item?.status
      });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  }
  if(props.name === "adminImageCategory"){
    columns = [
      { field: "uuid", headerName: "ID", flex: 0.01,minWidth: 100,sortable:false},
      { field: "name", headerName: "Name", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
        return (
         <Tooltip title={params.row.name}>
          <p >{ StringShortner(params.row.name,10)}</p>
         </Tooltip>
        )
      }},
      { field: "status", headerName: "Status", flex: 0.1 ,minWidth: 100,sortable:false, renderCell: (params: any) => {
        let classtatus:boolean = params.row.status;
          const handleChange = async ()=>{
            let status:boolean = params.row.status;
            const update:any = await updateStatusImageCategory(params.row.uuid,!status);
            if(update.status === "success"){
              classtatus = !params.row.status;
              const newData:any = tableRow.map((item:any)=>{
                if(item.id === params.row.id){
                    return { ...item, status: classtatus, };
                }
                return item;
            })
            settableRow(newData);
            return  toast.success(update.message);
            }
          }
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange}  checked={classtatus} />
            </div>
          );
        }},
      { field: "", headerName: "Action", flex: 0.14 ,minWidth: 150,sortable:false, renderCell: (params: any) => {
          const handleDelete =  async()=>{
            let classname = `delete ${params.row.name}`;
          Swal.fire({
            title: 'Are you sure want to delete ?',
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async(value) => {
              const del:any = await deleteImageCategory(params.row.id)
                  if(del?.status === 'success'){
                    const newData = tableRow.filter((item:any)=> item.id !== params.row.id);
                    settableRow(newData);
                    setTotalPages(totalPages-1);
                    setTotalRow(totalRow-1);
                   return toast.success(del.message);
                  }

            }
          })

          }
          const handleEdit = ()=>{
            setOpen(!open)
            setModelDate({
              id: params.row.id,
            })
          }
          return (
            <div className="flex gap-4 flex-row">
              <TableButton title="edit" icon={true}   onClick={handleEdit} toottip="edit" />
              <TableButton title="delete" icon={true}   onClick={handleDelete} toottip="delete" />
            </div>
          );
        }},
    ]
  }
  const adminImages = async()=>{
    setloading(true)
    let get;
    if (filterModel === null || filterModel === undefined || filterModel === "") {
       get = await getImage(page);
    } else {
      get = await getImage(page,filterModel.quickFilterValues.join(' '));
    }
    setTotalPages(get.message.totalPage * pageSize);
    setTotalRow(get.message.totalRow);
    let dt: any = [];
    if (get?.status == "success") {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
      dt.push({
        id: item.id,
        uuid:index+rowid,
        name: item.name,
        img:item.image_url,
        amount:item.amount,
        discount:item.discount,
        category:item.category.name,
       status: item?.status
      });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  }
  if(props.name === "adminImages"){
    columns = [
        { field: "uuid", headerName: "ID", flex: 0.01,minWidth: 100,sortable:false},
        { field: "name", headerName: "Name", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
          return (
           <Tooltip title={params.row.name}>
            <p >{ StringShortner(params.row.name,20)}</p>
           </Tooltip>
          )
        }},
        { field: "category", headerName: "Category", flex: 0.1,minWidth: 100,sortable:false},
        { field: "img", headerName: "Image", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
            return (
             <Tooltip title={params.row.name}>
             <img src={params.row.img}  className="w-[100px] p-2"/>
             </Tooltip>
            )
          }},
          { field: "amount", headerName: "Amount", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
            return (

                    <><FaRupeeSign /> {params.row.amount}</>

            )
          }},
          { field: "discount", headerName: "Discount", flex: 0.14 ,minWidth: 150,sortable:false,renderCell: (params: any) => {
            return (
                <><FaRupeeSign /> {params.row.discount}%</>
            )
          }},
        { field: "status", headerName: "Status", flex: 0.1 ,minWidth: 100,sortable:false, renderCell: (params: any) => {
          let classtatus:boolean = params.row.status;
            const handleChange = async ()=>{
              let status:boolean = params.row.status;
              const update:any = await updateStatusImage(params.row.id,!status);
              if(update.status === "success"){
                classtatus = !params.row.status;
                const newData:any = tableRow.map((item:any)=>{
                  if(item.id === params.row.id){
                      return { ...item, status: classtatus, };
                  }
                  return item;
              })
              settableRow(newData);
              return  toast.success(update.message);
              }
            }
            return (
              <div className="flex gap-4 flex-row">
                <SwitchButton onchange={handleChange}  checked={classtatus} />
              </div>
            );
          }},
        { field: "", headerName: "Action", flex: 0.14 ,minWidth: 150,sortable:false, renderCell: (params: any) => {
            const handleDelete =  async()=>{
              let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async(value) => {
                const del:any = await deleteImage(params.row.id)
                    if(del?.status === 'success'){
                      const newData = tableRow.filter((item:any)=> item.id !== params.row.id);
                      settableRow(newData);
                      setTotalPages(totalPages-1);
                      setTotalRow(totalRow-1);
                     return toast.success(del.message);
                    }

              }
            })

            }
            const handleEdit = ()=>{
             navigate(`/admin/images/${params.row.id}/edit`)
            }
            return (
              <div className="flex gap-4 flex-row">
                <TableButton title="edit" icon={true}   onClick={handleEdit} toottip="edit" />
                <TableButton title="delete" icon={true}   onClick={handleDelete} toottip="delete" />
              </div>
            );
          }},
      ]
  }
  // adminImageCategory
  useEffect(() => {
    if(props.name === "addressBookData"){
      addressBookData()
    }
    if(props.name === "adminImages"){
        adminImages()
    }

  }, [props.refresh, page, pageSize, setFilterModel,filterModel,props.dataObject]);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRow !== undefined ? totalRow : prevRowCountState
    );
  },[totalRow])

  useEffect(()=>{
      if(props.name === "adminImageCategory"){
        adminImageCategory()
      }
    setRowCountState((prevRowCountState) =>
      totalRow !== undefined ? totalRow : prevRowCountState
    );
  },[contentUpdate])
  const onPaginationModelChange = (paginationModel: any) => {
    setRowid(10*paginationModel.page+1);
    setPage(paginationModel.page + 1);
    setPaginationModel(paginationModel);
  };
  useEffect(() => {

    // const selectedData = rowSelectionModel.map((id:any) => tableRow.find((item:any, index:number) => item.id === id));
    if(props.onSubmit){
      props.onSubmit(rowSelectionModel);
    }
  },[rowSelectionModel])
  return (
    <>
    <ThemeProvider theme={theme}>

        <Box
          sx={{ height: props.height ? props.height : 450, width: 1 }}
          className={` shadow-md rounded-xl p-2 ${localStorage.getItem('theme') === "dark" ? " bg-dark-purple1" : "bg-gray-200"} `}
        >
          <DataGrid
            sx={{ border: 0, width:'100%',}}
            rows={tableRow}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel:any) => {
              // const seletedId:any = tableRow.filter((row:any)=> row.id === newRowSelectionModel[0])
              //    setRowSelectionModel(seletedId);
              if(newRowSelectionModel.length > 0) {
                setRowSelectionModel([...newRowSelectionModel]);
              }else{
                setRowSelectionModel([]);
              }
              }}
              keepNonExistentRowsSelected
            checkboxSelection={props.checkbox}
            rowCount={rowCountState}
            columns={columns}
            loading={loading}
            paginationMode="server"
            paginationModel={paginationModel}
            // onPaginationModelChange={setPaginationModel}
            onPaginationModelChange={onPaginationModelChange}

            // Set the total row count
          />
        </Box>

       {
        props.name === "adminImageCategory" ?
        <Models  name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}  >
        <AdminImageCategoryCE data={modelData} type="edit" open={setOpen}  />
      </Models>
        :null
       }


      </ThemeProvider>

    </>
  );
}
