
export const StringShortner = (str:string,length:number)=>{
    if(str.length <= length){
        return str
    }else{
        return str.slice(0,length) + '...'
    }
}

export const statusList  = [
    {value:'true',label:"Active"},
    {value:'false',label:"Inactive"},
]
export const toBoolean = (value:any) =>{
    if(value === "true"){
        return true
    }
    return false 
}