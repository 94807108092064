export const formValidationRules = {
    required: (message: string = 'This field is required') => ({
      required: message ? message : 'This field is required',
    }),
    minLength: (length: number, message: string = `Minimum length is ${length}`) => ({
      minLength: {
        value: length,
        message,
      },
    }),
    maxLength: (length: number, message: string = `Maximum length is ${length}`) => ({
      maxLength: {
        value: length,
        message,
      },
    }),
    pattern: (pattern: RegExp, message: string = 'Invalid format') => ({
      pattern: {
        value: pattern,
        message,
      },
    }),
    min: (value: number, message: string = `Minimum value is ${value}`) => ({
      min: {
        value,
        message,
      },
    }),
    max: (value: number, message: string = `Maximum value is ${value}`) => ({
      max: {
        value,
        message,
      },
    }),
  };
  
  export default formValidationRules;
  