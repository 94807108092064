import React, { useEffect, useState } from 'react'
import Clock from '../../components/calendar/Clock'
import Calendar from '../../components/calendar/Calendar'
import moment from 'moment'
import { FaAddressBook, FaBookmark, FaUsers } from 'react-icons/fa'
import { Fa42Group } from 'react-icons/fa6'
import { dashboardAddressBook } from '../../service/addressbookService'
import { Skeleton } from '@mui/material'
import MUITableGrid from '../../components/tables/MUITableGrid'

export default function AdminDashboard() {
  const [firstRow, setFirstRow] = useState<any>(null)
  const fetch = async () => {
   let get = await dashboardAddressBook()
    if(get?.status === "success"){
      setFirstRow(get.message)
    }
  }
  useEffect(()=>{
    document.title = 'Dashboard';
    fetch()
  },[])

  return (
   <>
    <div className="grid grid-cols-12 gap-3">
      <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 p-2">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-12 md:col-span-4">
              {
                firstRow === null ?
                <Skeleton variant='rounded' height={90} />
                :
                <div className='bg-gray-200 rounded-xl p-3 dark:bg-[#0b0b0b2d]'>
                <div>
                <p className='text-sm text-gray-800 dark:text-gray-50'> Today New Contacts</p>
                <div className='flex flex-row justify-between'>
                  <p className=' text-3xl font-bold pl-3 pt-2 text-purple-700'>{firstRow?.todayCount}</p>
                  <FaUsers className=' text-5xl text-purple-700' />
                </div>
                </div>
                  
              </div>
              }
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-4">
             {
              firstRow === null ?
              <Skeleton variant='rounded' height={90} />
              :
              <div className='bg-gray-200 rounded-xl p-3 dark:bg-[#0b0b0b2d]'>
              <div>
              <p className='text-sm text-gray-800 dark:text-gray-50'> All Contacts</p>
              <div className='flex flex-row justify-between'>
                <p className=' text-3xl font-bold pl-3 pt-2 text-purple-700'>{firstRow?.allCount}</p>
                <FaAddressBook className=' text-5xl text-purple-900' />
              </div>
              </div>
                
            </div>
             }
            </div>
           
            <div className="col-span-12 sm:col-span-12 md:col-span-4">
              {
                firstRow === null ? 
                  <Skeleton variant='rounded' height={90} />
                :
                <div className='bg-gray-200 rounded-xl p-3 dark:bg-[#0b0b0b2d]'>
                <div>
                <p className='text-sm text-gray-800  dark:text-gray-50'> Total Groups</p>
                <div className='flex flex-row justify-between'>
                  <p className=' text-3xl font-bold pl-3 pt-2 text-purple-700'>{firstRow?.allGroup?.length}</p>
                  <FaBookmark className=' text-5xl text-purple-900' />
                </div>
                </div>
                  
              </div>
              }
            </div>
          </div>
          <div className='mt-5 dark:bg-dark-purple1 p-2 rounded-md bg-gray-200'>
            <p>Recent Contacts</p>
            <MUITableGrid name="dashboardTable" />
          </div>
      </div>
      <div className=" col-span-4 sm:col-span-12 md:col-span-6 lg:col-span-4 p-2 hidden sm:hidden md:block">
        <div className=' flex  flex-col  bg-gray-200 dark:bg-[#0b0b0b2d] min-h-[80vh]' >
          <div className='flex justify-center mb-5'>
          <Clock/>
          </div>
          <Calendar  date={moment().format("YYYY-MM-DD")}/>
        </div>
      </div>
    </div>
   </>
  )
}


