import instance from "./instace"

export const createImageCategory = async(name:string,status:boolean)=>{
    try{
        const get:any = await instance.post('/api/admin/image-category/create',{
            name,
            status
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getImageCategory = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/image-category/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showImageCategory  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/image-category/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}


export const editImageCategory = async(id:string,name:string,status:boolean)=>{
    try{

     const get:any = await instance.put(`/api/admin/image-category/edit/${id}`,{
        name,
        status
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStatusImageCategory = async(id:string,status:boolean)=>{
    try{

     const get:any = await instance.put(`/api/admin/image-category/status/${id}`,{
        status
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const deleteImageCategory = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/image-category/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getActiveImageCategory  = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/image-category/get/active`);
        return get.data;
    }catch(e){
            return false;
    }
}
