import { CircularProgress, LinearProgress } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { FaTimes } from 'react-icons/fa';
import { mediaEndPoint } from '../../service/instace';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

interface FileProgress {
    [filename: string]: number;
  }

  interface FileEntry {
    filename: string;
    size:number,
    file:any,
    type:any,
    controller: AbortController;
  }

export default function DropZoneUploadImages(props:{
    status: any
    data:any
    maxFile?:number
    title?:string
    url?:string
    isRequired?:boolean
    heigth?:string
    filename?:any

  }) {
    const [fileEntries, setFileEntries] = useState<FileEntry[]>([]);
    const [fileUploaded, setUploaded] = useState({
      status: false,
      url:  '',
      name:""
    })
    const [startUploading, setStartUploading] = useState(false)
    const [files, setFiles] = useState<any>(null);
    const onDrop = useCallback(async(acceptedFiles: File[]) => {
      const selectedFile:any = acceptedFiles[0];
      setFiles(Object.assign(selectedFile, {
        preview: URL.createObjectURL(selectedFile)
      }));
      const newEntries = acceptedFiles.map((file) => ({
        filename: file.name,
        size:file.size,
        file:file,
        type:file.type,
        controller: new AbortController(),
      }));
      setFileEntries((prevEntries) => [...prevEntries, ...newEntries]);
      handleUpload(acceptedFiles[0]);
      setStartUploading(true)
    }, []);
      useEffect(()=>{
        console.log(props.url)
        if(props.url){
          let len:number = props.url?.split('/').length
        setUploaded({
          status: true,
          url: props.url,
          name:props.url?.split('/')[len-1]
        });

        }
      },[props.url])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'image/*': ['.gif', '.jpeg', '.pjpeg','.jpg', '.webp','.png', '.bmp', '.svg+xml'],
        },
      maxFiles: 1,
      multiple: false,
    });
    const clearFiles = async() => {
        setUploaded({
           status: false,
      url: '',
      name:""
          });
        setFiles(null);
      setStartUploading(false)
      setFileEntries([])
      props.data(null)
    };
    const handleUpload = async (entries: any) => {
      try{
        const formData = new FormData();
        formData.append('image', entries);
        const upload:any =await axios.post(`${mediaEndPoint}/api/image/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':Cookies.get("token")
            },
          }

      )
      if(upload.data?.status === "success"){
        console.log(upload.data?.url)
        props.data(upload.data?.url)
        let len:number = upload.data?.url.split('/').length
        // props.filename(upload.data?.url.split('/')[len-1])
        setUploaded({
          status: true,
          url: upload.data?.url,
          name:upload.data?.url.split('/')[len-1]
        });
      }
      }catch(error:any){
        setFiles(null);
        setStartUploading(false)
        setFileEntries([])
        setUploaded({

                status: false,
           url: '',
           name:""
        })
        props.data(null)
        if (error.response?.status === 404) {
          return toast.error("Api not found, contact to administrator");
        }
        if (error.response?.status !== 200) {
          return toast.error(error?.response?.data?.message);
        }
      }
    };


    return (
      <>
      <label  className={`text-gray-600 dark:text-gray-400 ml-1`}>
      {props.title} {props.isRequired ? <sup className="text-red-500">*</sup> : null}
    </label>
      <div className='p-2 rounded-xl w-full border border-gray-500 border-dashed bg-gray-200 dark:bg-gray-700'>

        {/* <div className={`min-h-[10vh]  grid place-content-center  ${startUploading ? 'hidden' : ' block'}`}  {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          </div> */}
          <div className={`${props.heigth ? props.heigth : 'min-h-[10vh]'}`}>
        {
          fileUploaded.status === false
          ?
          fileEntries.length > 0  ?
            fileEntries.map((e:any)=>(
              <>
            <div className=' flex gap-3 items-center'>
              <p>{e.filename} </p>
              <CircularProgress size={26} />
            </div>
            </>
            ))
          :
          <div className={`${props.heigth ? props.heigth : 'min-h-[10vh]'}  grid place-content-center  ${startUploading ? 'hidden' : ' block'}`}  {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          :
          <div className='grid place-content-center '>
            <img src={fileUploaded.url} alt="" />
            <button type='button' className='bg-red-500  mt-1 rounded-md text-white font-normal' onClick={clearFiles}>Remove Image?</button>
          </div>
 }

        </div>




      </div>
      </>
    );
  };
