import axios from "axios";
import React, { useState } from "react";
import { endPoint1, mediaEndPoint } from "../../service/instace";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setContentUpdate } from "../../redux/contentUpdateSlice";
import MUITableGrid from "../tables/MUITableGrid";

export default function WordUpload(props: {
  name: string;
  accept?: string;
  data?: any;
  open?: any;
}) {
  const [file, setFile] = useState<any>(null);
  const [notCreated, setNotCreated] = useState([])
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async () => {

    if (!file) {
      console.error("No file selected.");
      return;
    }
    if(props.name === "adminImageObject"){
      const formData = new FormData();
      formData.append("file", file);
      try {
        let fetch: any = await axios.post(
          `${mediaEndPoint}/api/upload/object`,
          formData,
          {
            headers: {
              authorization: Cookies.get("token"),
            },
          }
        );
        if (fetch?.data?.status === "success") {
        //   props.open(false);
        //   dispatch(
        //     setContentUpdate({
        //       id: Math.random() * (100000 - 111111),
        //     })
        //   );
          props.data(fetch.data?.url)
          return toast.success("file successfully uploaded");
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error?.response?.data)
        return toast.error(error?.response?.data.message);
      }
    }
  };

  return (
    <div className="">
      <form className="p-2 flex flex-row gap-2 " >
      <input
        className="w-[80%] flex items-center text-lg p-1 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        type="file"
        accept={`${props.accept ? props.accept : "*"}`}
        onChange={handleFileChange}
      />
      <button
      onClick={handleSubmit}
      type="button"
        disabled={loading ? true : false}
        className="p-2  w-[15%] flex   gap-1 items-center justify-center hover-purple-gradient text-white rounded-lg"
      >
        {loading ? " Uploading..." : "upload"}
      </button>
    </form>
   {
    notCreated.length > 0 ?
    <div className="my-2 bg-gray-100 p-1 rounded-lg">

      <p className="text-red-500 my-3 pl-3">Data Not Created</p>
    <MUITableGrid name="studentsNotCreatedFromExcel"  height={320} dataObject={notCreated}/>
    </div>
    :null
   }
    </div>

  );
}
