import Box from "@mui/material/Box";
import {
  DataGrid,
  GridRowSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState} from "react";

import { useNavigate } from "react-router-dom";

import { Switch, ThemeProvider,  Tooltip,  createTheme } from '@mui/material';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaEdit, FaEye, FaPlus, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2'
import moment from "moment";

export default function  MUITableGrid(props: {
  name: String;
  id?:any;
  refresh?: any;
  height?: any;
  dataId?:any;
  dataObject?:any;
  onSubmit?:any;
  checkbox?:boolean;
  anyData?:any

}) {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const themeSlice = useSelector((state:any) => state.themeSlice)
    const theme:any = createTheme({
        palette: {
            mode: themeSlice? themeSlice : 'light',
          },
      });
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const [columnWidths, setColumnWidths] = useState({});
  const [modelData, setModelDate] = useState<any>([]);
  const [rowLoading, setRowLoading] = useState(false)
  const handleColumnWidthChange = (newColumnWidths:any) => {
    setColumnWidths(newColumnWidths);
  };
  const [loading, setloading] = useState<boolean>(false);
  let columns: any = [];
  let [tableRow, settableRow] = useState(props.dataObject ? props.dataObject : []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [rowid, setRowid] = useState<any>(1)
  const [updateModelData, setUpdateModelData] = useState<any>();
  const [rowCountState, setRowCountState] = useState(totalRow || 0);
  const courseUpdateData = useSelector((state:any) => state.courseUpdateSlice)
  const [filterModel, setFilterModel] = useState<any>({
    items: [],
    quickFilterValues: [],
  });

  const dashboardTable = async()=>{
    const {  dashboardAddressBookRecent }  = require("../../service/addressbookService");
    setloading(true)  
    let  get = await dashboardAddressBookRecent();
    let dt: any = [];
    if (get?.status == "success") {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
      dt.push({
        id: index+rowid,
        uuid:item.id,
        name: item.name,
        phone: item.phone,
        email: item.email,
      });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  }
  if(props.name === "dashboardTable"){
    columns = [
      { field: "id", headerName: "ID", flex: 0.1 ,minWidth: 100},
      { field: "name", headerName: "Name", flex: 0.2,minWidth: 120 },
      { field: "phone", headerName: "Phone", flex: 0.2,minWidth: 120},
      { field: "email", headerName: "Email", flex: 0.3,minWidth: 120 },
    ]
  }
  useEffect(() => {
    if(props.name === "dashboardTable"){
      dashboardTable()
    }
  }, [props.refresh, page, pageSize, settableRow,totalRow, setFilterModel,filterModel, props.dataObject]);



  return (
    <>
    <ThemeProvider theme={theme}>
     
        <Box
          sx={{ height: props.height ? props.height : 350, width: 1 }}
          className={`  rounded-xl p-2 ${localStorage.getItem('theme') === "dark" ? "bg-dark-purple1" : "bg-gray-200"} `}
        >
          <DataGrid
            sx={{ border: 0, width:'100%',}}
            rows={tableRow}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={(newRowSelectionModel:any) => {
              if(newRowSelectionModel.length > 0) {
                setRowSelectionModel([...newRowSelectionModel]);
              }
              }}
              rowSelectionModel={rowSelectionModel}
            checkboxSelection={props.checkbox ? props.checkbox : false }
            columns={columns}
            loading={loading}
            // onPaginationModelChange={setPaginationModel}

            // Set the total row count
          />
        </Box>
      </ThemeProvider>
     
    </>
  );
}
