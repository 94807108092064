import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../components/button/CustomButton'
import { addressBookTypes } from '../types/AdminType';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FormButton from '../components/button/FormButton';
import Input from '../components/form/Input';
import formValidationRules from '../helpers/formvalidationRules';
import { useLocation, useNavigate } from 'react-router-dom';
import { createAddressBook, editAddressBook, showAddressBook } from '../service/addressbookService';
import moment from 'moment';

export default function AddressBookCE() {
  const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create New Contact",
    })
    let location = useLocation();
    let pageType = location.pathname.split('/')[location.pathname.split('/').length-1];
    let dataid = location.pathname.split('/')[location.pathname.split('/').length-2];
    let navigate = useNavigate()
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showAddressBook(id)
      if(get?.status === "success"){
        setFetchData(get.message)
      }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(pageType === "edit"){
          fetchBatchDetail(dataid)
            setPageContent({
                button:"Update Contact"
            })
        }
      
        setPageLoad(false)
      },[])


      const { handleSubmit, control } = useForm<addressBookTypes>({
        values:{
            name:fetchData?.name,
            email:fetchData?.email,
            phone:fetchData?.phone,
            dob:moment(fetchData?.dob).format("YYYY-MM-DD"),
            website:fetchData?.website,
            group:fetchData?.group,
        }
      });
      const onSubmit: SubmitHandler<addressBookTypes> = async(data:addressBookTypes) => {
        console.log(data)
        console.log(pageType)
        setLoading(true);
        let fetch:any;
       if(pageType==="create"){
        fetch = await createAddressBook(data);
       }
       if(pageType === "edit"){
        fetch = await editAddressBook(fetchData?.id,data);
       }
       if(fetch?.status=== "success"){
        setLoading(false)
        navigate("/admin/addressbook")
        return toast.success(fetch.message);
      }
      setLoading(false)
      
      };  
  return (
    <>
    <BreadcrumbBack title={pageContent.button} >
    </BreadcrumbBack>

    <div className="py-3 md:py-8 px-1 md:px-3 bg-gray-300 dark:bg-[#0b0b0b2d] p-3 rounded-xl my-5">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
   
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
        <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Full Name"
       placeholder="full name"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6 ">
        <Input 
       name="phone"
       type='number'
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(10)
       }}
       label="Phone Number"
       placeholder="phone name"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6 ">
        <Input 
       name="email"
       type='email'
       control={control}
       rules={{
        ...formValidationRules.required(),
       }}
       label="Email"
       placeholder="email"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6 ">
        <Input 
       name="dob"
       control={control}
       type='date'
       rules={{
        ...formValidationRules.required(),
       }}
       label="Date of Birth"
       placeholder="Name"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6 ">
        <Input 
       name="group"
       control={control}
       type='text'
       rules={{
        ...formValidationRules.required(),
       }}
       label="Group"
       placeholder="group"
      />
        </div>
        <div className="col-span-12  ">
        <Input 
       name="website"
       control={control}
       type='text'
       label="Website Address"
       placeholder="website address"
      />
        </div>
      </div>
       <div className="mt-3">
       <FormButton loading={loading} title={pageContent.button} />
       </div>
      </form>
    </div>
    </>
  )
}


