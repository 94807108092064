import React, { useEffect, useState } from 'react'
import { getMainPageImage } from '../../service/images.service';
import { chunkArray } from '../../helpers/Helper';
import { FaLayerGroup } from 'react-icons/fa';

export default function Index() {
    const [data, setData] = useState<any>([]);
const [nextCursor, setNextCursor] = useState(null);
const [loading, setLoading] = useState(false);

const fetchData = async () => {
    setLoading(true);
    const response:any = await getMainPageImage(nextCursor);
    if(response.status === "success") {
        const chunks = chunkArray(response.message.data, 1);
        setData((prevData:any) => [...prevData, ...chunks]);

    setNextCursor(response.message.nextCursor);
    setLoading(false);
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  return (
   <>
    <div className='flex justify-center my-2'>
        <img src="https://demo.mekshq.com/pinhole/wp-content/themes/pinhole/assets/img/pinhole_logo@2x.png" alt="" />
    </div>
    <div>
    <div className='grid grid-cols-12  md:grid-cols-4 gap-2'>
    {data.map((item:any,index:number) => (
      <div key={`main${index}`} className=' grid gap-4 p-2 relative'>

        {item.map((it:any,ind:number) =>(

            <div key={`main1${ind}`} className='h-full max-w-full flex items-center bg-gray-950 rounded-xl hover:cursor-pointer  relative z-[-1]'>
            {/* <FaLayerGroup className={` text-3xl text-white absolute top-0  `}/> */}

              <img className="h-auto max-w-full rounded-lg" src={it.image_url} alt="" />
            </div>
        ))}
      </div>
    ))}
    </div>
    {nextCursor && (
      <button onClick={fetchData} disabled={loading}>
        {loading ? 'Loading...' : 'Load More'}
      </button>
    )}
  </div>
   </>
  )
}
