import moment from 'moment'
import React, { useEffect, useState } from 'react'

export default function Clock() {

    const [time, setTime] = useState<any>(new Date())
    useEffect(()=>{
      let timer = setInterval(()=>{setTime(new Date())},30000)
      return function Cleanup() {
        clearInterval(timer)
      }
    })
  return (
    <div className='font-bold text-[3rem] text-purple-600 dark:text-purple-400'>{moment(time).format("hh:mm A")}</div>
  )
}
