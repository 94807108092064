import { adminImagesType } from "../types/AdminType";
import instance from "./instace"

export const createImage = async(data:adminImagesType)=>{
    try{
        const get:any = await instance.post('/api/admin/image/create',{
            fk_category_id:data.fk_category_id,
            name:data.name,
            description:data.description,
            keywords:data.keywords,
            amount:data.amount,
            discount:data.discount,
            status:data.status,
            url:data.url,
            object_url:data.object_url
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getImage = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/image/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showImage  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/image/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}


export const editImage = async(id:string,data:adminImagesType)=>{
    try{

     const get:any = await instance.put(`/api/admin/image/edit/${id}`,{
        fk_category_id:data.fk_category_id,
        name:data.name,
        description:data.description,
        keywords:data.keywords,
        amount:data.amount,
        discount:data.discount,
        status:data.status,
        url:data.url,
        object_url:data.object_url
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStatusImage = async(id:string,status:boolean)=>{
    try{

     const get:any = await instance.put(`/api/admin/image/status/${id}`,{
        status
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const deleteImage = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/image/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const getMainPageImage = async(nextCursor:any)=>{
    try{

        const get:any = await instance.get(`/api/admin/image/get/main-page?cursor=${nextCursor}&take=6`)
        return get.data;
    }catch(e){
        return false;
    }
}
