import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from './pages/Login';
import Register from './pages/Register';
import { useSelector } from 'react-redux';
import Admin from './pages/admin/Admin';
import AdminDashboard from './pages/admin/AdminDashboard';
import AddressBook from './pages/admin/AddressBook';
import AddressBookCE from './pages/AddressBookCE';
import AdminImage from './pages/admin/images/AdminImage';
import AdminImageCE from './pages/admin/images/AdminImageCE';
import AdminImageCategory from './pages/admin/images/imageCategory/AdminImageCategory';
import Index from './pages/public/Index';
function App() {
  const isAdmin =  useSelector((state: any) => state.loginSlice.isLoggedIn);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense>
      <Routes>
        <Route path="/" element={<Index/>} />
        <Route path="/admin/login" element={<Login/>}></Route>
        <Route path="/admin" element={isAdmin ? <Admin /> : <Login/>}>
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="addressbook" element={<AddressBook />} />
        <Route path="addressbook/create" element={<AddressBookCE />} />
        <Route path="addressbook/:id/edit" element={<AddressBookCE />} />
        <Route path="images" element={<AdminImage />} />
        <Route path="images/create" element={<AdminImageCE />} />
        <Route path="images/:id/edit" element={<AdminImageCE />} />
        <Route path="image/category" element={<AdminImageCategory />} />
        </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
