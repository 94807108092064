import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const endPoint1 = "https://server.imgbunch.in"
export const mediaEndPoint  ="https://content.imgbunch.in"
const instance = axios.create({
  baseURL:endPoint1
});
const token = Cookies.get("token");
instance.defaults.headers.common["Authorization"] = token;

instance.interceptors.response.use(
  (response:any) => {
    return response;
  },
  (error:any) => {
    if (error.response.status === 401) {
        if(Cookies.get("role") === "admin"){
          Cookies.remove("token")
          Cookies.remove('user')
          Cookies.remove('login')
          toast.error(error.response.data.message)
        }
        if(Cookies.get("role") === "superAdmin"){
          Cookies.remove("token")
          Cookies.remove('user')
          Cookies.remove('login')
          toast.error(error.response.data.message)
        }
        if(Cookies.get("role") === "student"){
          Cookies.remove("token")
          Cookies.remove('user')
          Cookies.remove('login')
          toast.error(error.response.data.message)
        }
        if(Cookies.get("role") === "teacher"){
          Cookies.remove("token")
          Cookies.remove('profile')
          Cookies.remove('user')
          Cookies.remove('login')
          toast.error(error.response.data.message)
        }
    }
    if (error.response.status === 403) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message)
    }
    if (error.response.status === 404) {
      console.log(error.response.data.message);
      toast.error("No File/Data Found")
    }
    if(error.response.status === 422){
      console.log(error.response.data.message)
        if(typeof(error.response.data.message) === "object"){
          Object.values(error.response.data.message).map((errorMessages:any, index:any) => {
            errorMessages.map((errorMessage:any, innerIndex:any) => {
              toast.error(errorMessage)
            })
          })
        }else{
          toast.error(error.response.data.message)
        }
      return false;
    }
    if (error.response.status === 500) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      toast.error(error.response.data.message)
    }
    // Return the error
  }
);

export default instance;
