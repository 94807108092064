import React, { useEffect } from 'react'
import BreadcrumbBack from '../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../components/button/CustomButton'
import { useNavigate } from 'react-router-dom'
import PaginationDataGrid from '../../components/tables/PaginationDataGrid'

export default function AddressBook() {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Address Book';
  }, [])
  return (
    <>
 <BreadcrumbBack title="Address Book" >
        <CustomButton onClick={(()=>navigate("/admin/addressbook/create"))}  title='Create New Contact' icon={true} iconName='create'    />
    </BreadcrumbBack>
    <div className="my-3">
        <PaginationDataGrid name="addressBookData" />
    </div>
    </>
  )
}
