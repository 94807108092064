import { Switch } from '@mui/material'
import React from 'react'

export default function SwitchButton(props:{
    checked?:boolean
    onchange? (event: React.ChangeEvent<HTMLInputElement>) : void;
}) {
  return (
    <Switch    sx={{
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#9c27b0', // Purple color
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#9c27b0', // Purple color
        },
      }} checked={props.checked}   onChange={props.onchange} size="medium"  />
  )
}
