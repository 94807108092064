import { configureStore } from '@reduxjs/toolkit';
import breadcrumSlice from './breadcrumSlice';
import mobileSideBar from './mobileSideBar';
import themeSlice from './themeSlice';
import loginSlice from './loginSlice';
import commonSlice from './commonSlice';
import contentUpdateSlice from './contentUpdateSlice';
const store = configureStore({
    reducer: {
        breadcrumSlice: breadcrumSlice,
        commonSlice:commonSlice,
        mobileSideBar:mobileSideBar,
        themeSlice:themeSlice,
        loginSlice:loginSlice,
        contentUpdateSlice:contentUpdateSlice
    },
});

export default store;