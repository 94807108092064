import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';

export default function AdminImage() {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Address Book';
  }, [])
  return (
    <>
 <BreadcrumbBack title="Images" >
        <CustomButton onClick={(()=>navigate("/admin/images/create"))}  title='Upload Image' icon={true} iconName='create' />
    </BreadcrumbBack>
    <div className="my-3">
        <PaginationDataGrid name="adminImages" />
    </div>
    </>
  )
}
