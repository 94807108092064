import React, { useEffect } from 'react'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs, { Dayjs } from 'dayjs';
import { FaCircle, FaDotCircle } from 'react-icons/fa';

function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  
  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? <FaCircle className=' scale-[0.5] text-purple-800' /> : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

export default function Calendar(props:{
  date:any,eventDate?:any,month?:any
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState<any>(dayjs());
  const [highlightedDays, setHighlightedDays] = React.useState(props.eventDate ? props.eventDate :[]);
    const themeSlice = useSelector((state:any) => state.themeSlice)
    const theme:any = createTheme({
        palette: {
            mode: themeSlice? themeSlice : 'light',
          },
      });
      useEffect(()=>{
        setHighlightedDays(props?.eventDate);
      },[props.eventDate])
      // console.log(highlightedDays)
      const handleMonthChange = (e:any)=>{
          // props.month(e)
          setHighlightedDays([])
      }
  return (
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar value={value} onChange={(newValue)=>{
        setValue(newValue)
        
      }}
      onMonthChange={handleMonthChange}
      loading={isLoading}
      slots={{
        day: ServerDay,
      }}
      slotProps={{
        day: {
          highlightedDays,
        } as any,
      }}
      />
        </LocalizationProvider>
    </ThemeProvider>
  )
}
