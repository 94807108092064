// SearchableSelect.tsx
import React, { useState } from 'react';
import Select from 'react-select';
import { Control, useController } from 'react-hook-form';

interface Option {
  value: string;
  label: string;
}

interface SearchableSelectProps {
  name: string;
  control: Control<any>;
  options: Option[];
  rules?: any;
  label: string;
  placeholder?: string;
  onFetch?: any;
  defaultValue?: Option;
  isClear?: boolean;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ name, control, options, rules, label, placeholder, defaultValue,onFetch,isClear  }) => {
  const { field, fieldState: { error } } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue ? defaultValue.value : ''
  });

  const handleChange = (selectedOption: Option | null) => {
    field.onChange(selectedOption ? selectedOption : '');
    if (selectedOption && onFetch) {
      onFetch(selectedOption);
    }
  };

  return (
    <div className="flex flex-col mb-3 ">
      <label htmlFor={name} className={`${error ? 'text-red-500' : 'text-gray-600 dark:text-gray-400'} ml-1`}>
        {label} {rules && rules.required && <sup className="text-red-500">*</sup>}
      </label>
      <Select
        {...field}
        id={name}
        isClearable={isClear ? true : false}
        styles={{
       
          control: (provided, state) => ({
            ...provided,
            zIndex: 999999,
            
            borderColor: state.isFocused ? 'rgb(126 34 206)' : error  ? 'rgb(239 68 68)' : 'rgb(126 34 206)',
            backgroundColor: localStorage.getItem('theme') === "dark" ? "rgb(55 65 81)" : "white",
            borderWidth:1.6,
            color:"white",
            borderRadius:8,
            "&:hover":{
              borderColor: state.isFocused ? 'rgb(126 34 206)' : error  ? 'rgb(239 68 68)' : 'rgb(126 34 206)',
            } // Change border color based on focus
            // You can adjust other border styles here like borderWidth, borderRadius, etc.
          }),
          menuPortal: (provided: any) => ({
            ...provided,
            zIndex: 9999, // Ensure the menu portal has a high z-index to appear above the modal
          }),
          menu: (provided: any) => ({
            ...provided,
            zIndex: 9999, // Ensure the menu has a high z-index
          }),
          input: (provided) => ({
            ...provided,
            color: localStorage.getItem('theme') === "dark" ? "white" : "gray", // Change the text color of the input
          }),
          placeholder: (styles) => ({ ...styles, 
            padding: 8,
            borderRadius: 10,
            border: 0,
            outline:0,
            color:"#a0aec0",
            backgroundColor: localStorage.getItem('theme') === "dark" ? "rgb(55 65 81)" : "white",
            borderColor: error  ? 'rgb(239 68 68)' : '#2d3748',
          }),
          
          singleValue: (base) => ({
            ...base,
            padding: 8,
            position:"relative",
            
            borderRadius: 5,
            color: localStorage.getItem('theme') === "dark" ? "white" : "rgb(55 65 81)",
            display: 'flex',
          }),
        }}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        value={options.find(option => option.value === field.value)}
      />
      {error && <small className='text-red-500 ml-2'>{error.message}</small>}
    </div>
  );
};

export default SearchableSelect;
