import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'

export default function FormButton(props:{
    title:string,
    loading?:boolean,
    onClick?:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    
}) {
  return (
    <button type="submit" onClick={props.onClick} disabled={props.loading} className={`${props.loading ? 'hover-purple-gradient' : 'hover-purple-gradient'} px-3 py-2 rounded-xl w-full  text-white flex gap-2 items-center  justify-center`}>
       {props.loading ?
        <CircularProgress sx={{
          color:"white"
        }} size={16}  /> : null
        }
         {
            props.title
         }
    </button>
  )
}
