import { addressBookTypes } from "../types/AdminType";
import instance from "./instace"

export const createAddressBook = async(data:addressBookTypes)=>{
    try{
        const get:any = await instance.post('/api/addressbook/create',{
            name:data.name,
            email:data.email,
            phone:data.phone,
            dob:data.dob,
            website:data.website,
            group:data.group,
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAddressBook = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/addressbook/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAddressBook  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/addressbook/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const dashboardAddressBook  = async()=>{
    try{
        const get:any = await instance.get(`/api/addressbook/dashboard/data`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const dashboardAddressBookRecent  = async()=>{
    try{
        const get:any = await instance.get(`/api/addressbook/dashboard/recent`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAddressBook = async(id:string,data:addressBookTypes)=>{
    try{

     const get:any = await instance.put(`/api/addressbook/edit/${id}`,{
        name:data.name,
            email:data.email,
            phone:data.phone,
            dob:data.dob,
            website:data.website,
            group:data.group,
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const deleteAddressBook = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/addressbook/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}