import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import Models from '../../../../components/models/Models';
import AdminImageCategoryCE from './AdminImageCategoryCE';

export default function AdminImageCategory() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
      document.title = 'Address Book';
    }, [])
    return (
      <>
   <BreadcrumbBack title="Images Category" >
          <CustomButton   title='New Category' onClick={(()=>setOpen(!open))} icon={true} iconName='create' />
      </BreadcrumbBack>
      <div className="my-3">
          <PaginationDataGrid name="adminImageCategory" />
      </div>
      <Models  name={`New Category`} isOpen={open} isClose={setOpen}  >
            <AdminImageCategoryCE type="create" open={setOpen}  />
          </Models>
      </>
    )
  }